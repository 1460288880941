import { reactive, toRefs } from 'vue'

const datosjuego = {
  noticias: {
    1: {
      titulo: 'Científicos estadounidenses hallan agua en la Luna  ',
      subtitulo: '',
      body: '<p>La Administración Nacional de Aeronáutica y el Espacio (NASA) informó este viernes que, gracias al estudio de una serie de datos del Observatorio Estratosférico de Astronomía Infrarroja (SOFIA), se detectó agua molecular (H2O) en la superficie de la Luna.  </p><br><p>Este nuevo hallazgo, divulgado por la revista científica Nature Astronomy, asegura que el H2O, que se encuentra en polvo o cristales, vibra cuando es estimulado por la luz del Sol y emite una onda de 6 micrómetros (µm), lo que quiere decir que la sustancia hallada en la superficie lunar corresponde a agua molecular.  </p><br><p>Nature Astronomy, además, publicó una investigación en la que expone que 400.000 m2 de la superficie lunar tienen la capacidad de almacenar agua en las ‘trampas frías’, término que se utiliza para denominar a los lugares de la Luna a los que no llega luz del Sol. Paul Hertz, director de Astrofísica de la NASA, afirmó: “Teníamos indicios de que el agua podría estar presente en el lado iluminado de la Luna (...) ahora este descubrimiento genera un desafío para la comprensión de la superficie lunar”.  </p><br><p>Cabe resaltar que en 2018 se encontró hidratación en la superficie lunar, sin embargo para ese momento no se pudo distinguir si la sustancia correspondía a agua molecular.  </p>',
      tipo: 'Verdadera',
      feedback_correcto: '¡Bien hecho! Esta fue una noticia verdadera, has hecho bien en confiar en esta información. Recuerda verificar las fuentes dentro de las noticias.',
      feedback_incorrecto: 'Esta era información verdadera. Recuerda que puedes confiar en información donde puedas identificar claramente los datos y fuentes que validan la noticia.'
    },
    2: {
      titulo: 'Un estudio revela que la superficie de Venus podría albergar vida  ',
      subtitulo: '',
      body: '<p>La revista científica Nature Astronomy publicó este jueves un estudio en el que señala el descubrimiento del gas fosfano (PH3) en la atmósfera del planeta Venus. En la Tierra, este gas se encuentra presente en microorganismos y en productos derivados de la industria humana, como por ejemplo los insecticidas, lo que genera grandes indicios del hallazgo de vida en este planeta.  </p><br><p>Jim Bridenstine, de la Administración Nacional de Aeronáutica y el Espacio (NASA), afirmó que “hace unos 10 años, la NASA halló vida microbiana a 120.000 pies en la atmósfera superior de la Tierra. Es hora de priorizar a Venus”. </p><br><p>Venus es el segundo planeta más cercano al Sol, es casi tan grande como la Tierra (12.070 km de diámetro), su superficie tiene una temperatura de 462 grados centígrados y su atmósfera está conformada por dióxido de carbono (Co2) y nitrógeno.   </p>',
      tipo: 'Verdadera',
      feedback_correcto: '¡Bien hecho! Esta fue una noticia verdadera, has hecho bien en confiar en esta información. Recuerda verificar las fuentes dentro de las noticias.',
      feedback_incorrecto: 'Esta era información verdadera. Recuerda que puedes confiar en información donde puedas identificar claramente los datos y fuentes que validan la noticia.'
    },
    3: {
      titulo: 'Unicef y Pure Earth alertan de la intoxicación por plomo en los niños  ',
      subtitulo: '',
      body: '<p>El Fondo de las Naciones Unidas para la Infancia (UNICEF) y la Organización No Gubernamental Pure Earth compartieron un informe en el que exponen que los niños se están viendo afectados por la intoxicación por plomo a nivel mundial.   </p><br><p>De los 800 millones de niños, aproximadamente, un tercio presenta iguales o mayores niveles de plomo en la sangre (5 microgramos por decilitro), de acuerdo con el límite de alerta declarado por la Organización Mundial de la Salud . “El plomo (...) causa estragos silenciosos en la salud y en el desarrollo de los niños, con consecuencias posiblemente fatales”, aseguró la Directora Ejecutiva de UNICEF, Henrietta Fore, en el texto publicado este jueves.  </p><br><p>Esta neurotoxina genera daños irreparables en el cerebro de los niños, lo que puede generar problemas neurológicos, físicos y cognitivos. El estudio, además, concluyó que América Latina alberga 50 millones de casos de intoxicación por plomo en niños.   </p>',
      tipo: 'Verdadera',
      feedback_correcto: '¡Has acertado! Esa información era confiable, pues contaba con las características como: datos precisos, contextualizados y redacción correcta. ',
      feedback_incorrecto: 'Dejaste ir una noticia verdadera. Elementos como una redacción correcta, el uso de datos precisos y relacionados al contexto, son señales de que puedes confiar en la noticia que estás leyendo. '
    },
    4: {
      titulo: '40.000 muertes al año en México se derivan del consumo de bebidas azucaradas ',
      subtitulo: '',
      body: '<p>Este lunes, el Instituto Nacional de Salud Pública (INSP) publicó un informe sobre el consumo de gaseosas, refrescos y, en general, bebidas azucaradas en el país, en el que advirtió que 40.000 muertes al año están relacionadas con su ingesta.    </p><br><p>En el estado de Chiapas y en la frontera sur de México, una persona puede llegar a consumir anualmente 821, 25 litros de bebidas azucaradas, mientras que el promedio de esta ingesta, por persona, equivale a 150 litros en el país y 25 litros a nivel mundial.  </p><br><p>El ente gubernamental hizo un llamado al Gobierno de México para la regulación del consumo de estos productos, teniendo en cuenta que siete de cada diez mexicanos sufre sobrepeso y la prevalencia de obesidad en el territorio es de 32.4%.  </p>',
      tipo: 'Verdadera',
      feedback_correcto: '¡Has acertado! Esa información era confiable, pues contaba con las características como: datos precisos, contextualizados y redacción correcta. ',
      feedback_incorrecto: 'Dejaste ir una noticia verdadera. Elementos como una redacción correcta, el uso de datos precisos y relacionados al contexto, son señales de que puedes confiar en la noticia que estás leyendo. '
    },
    5: {
      titulo: 'Despega el cohete Falcon 9 de la compañía SpaceX ',
      subtitulo: 'Por Carmen Pérez  ',
      body: '<p>Este sábado, Douglas Hurley y Robert Behnken, astronautas de la Administración Nacional de Aeronáutica y el Espacio (NASA), despegaron en el Cohete Falcon 9, que transporta la cápsula Crew Dragon Demo-2, rumbo a la Estación Espacial Internacional (EEI).   </p><br><p>Se espera que la cápsula, de la empresa SpaceX, conecte con el centro espacial 19 horas después del despegue (3:22 p.m. EDT), que se llevó a cabo en el Centro Kennedy de Cabo Cañaveral en el estado de Florida (Estados Unidos).  </p><br><p>Hurley y Behnken analizarán los sistemas de la cápsula Crew Dragon para comprobar la eficacia de las misiones de exploración operadas por compañías privadas. Este lanzamiento es el primer viaje tripulado desde EE. UU. en nueve años.  </p>',
      tipo: 'Verdadera',
      feedback_correcto: '¡Excelente! La información confiable cuenta con el nombre del autor en un lugar visible dentro de la publicación.',
      feedback_incorrecto: 'Recuerda que una de las razones para desconfiar de una noticia, es no identificar su nickname o que sea sospechoso. En este caso el nombre del autor de la noticia era visible.'
    },
    6: {
      titulo: 'El daño a la naturaleza afectará a las economías de los países  ',
      subtitulo: '',
      body: '<p>El Fondo Mundial para la Naturaleza (WWF) publicó un informe en el que advierte que si los países continúan con su modelo de desarrollo actual tendrán grandes consecuencias en sus economías en los próximos 30 años.  </p><br><p>Estados Unidos, Japón y Reino Unido podrían perder entre 83.000 y 21.000 millones de dólares cada año hasta 2050 debido a la pérdida de arrecifes de coral y los manglares, al daño en las costas, la erosión, las inundaciones, entre otros.  </p><br><p>El director general de WWF, Marco Lambertini, afirmó: “Este estudio innovador muestra el hecho de que conservar la naturaleza no es solo una cuestión moral, sino también social y económica”.  </p><br><p>Cabe resaltar que debido a este impacto económico los niveles de producción, el comercio y los precios de los alimentos se verán afectados considerablemente a nivel mundial.  </p>',
      tipo: 'Verdadera',
      feedback_correcto: '¡Has acertado! Esa información era confiable, pues contaba con las características como: datos precisos, contextualizados y redacción correcta. ',
      feedback_incorrecto: 'Dejaste ir una noticia verdadera. Elementos como una redacción correcta, el uso de datos precisos y relacionados al contexto, son señales de que puedes confiar en la noticia que estás leyendo. '
    },
    7: {
      titulo: 'La deforestación de la Amazonía aumentó 54% en menos de un año  ',
      subtitulo: 'Por Clara Peralta  ',
      body: '<p>El 2019 fue un año en el que los incendios de la Amazonía fueron una alerta acerca de la problemática de la deforestación en este territorio de 6,7 millones de kilómetros cuadrados. Entre agosto de 2019 y agosto de 2020 se deforestaron 4.567 kilómetros cuadrados de la selva amazónica, una cifra alarmante teniendo en cuenta que en esta época del año es cuando más se presentan incendios en dicho lugar.  </p><br><p>El Sistema de Alerta de Deforestación del Instituto del Hombre y el Medio Ambiente de la Amazonía (Imazon) aseguró que, solo en mayo de 2020, 649 km2 de selva nativa fue deforestada, la segunda tasa más alta de esta prácica para este mes en los últimos diez años.   </p><br><p>Es importante resaltar que el crecimiento de la deforestación en la Amazonía se deriva de la expansión de la ganadería extensiva, las concesiones mineras y la construcción de hidroeléctricas en este terreno.  </p>',
      tipo: 'Verdadera',
      feedback_correcto: '¡Excelente! La información confiable cuenta con el nombre del autor en un lugar visible dentro de la publicación.',
      feedback_incorrecto: 'Recuerda que una de las razones para desconfiar de una noticia, es no identificar su nickname o que sea sospechoso. En este caso el nombre del autor de la noticia era visible.'
    },
    8: {
      titulo: 'Messi alcanza los 708 goles en su carrera profesional  ',
      subtitulo: '',
      body: '<p>El futbolista argentino Lionel Messi llegó a 708 anotaciones en su carrera profesional, después del tanto que marcó, este miércoles, frente a Juventus durante el encuentro del grupo G de la Liga de Campeones. Messi suma 739 goles con el club español Barcelona y 71 con la selección de su país. </p><br><p>Con un promedio de 0,81 tantos por partido, el delantero nacido en Rosario (Argentina), se encamina a alcanzar el récord de marcar la mayor cantidad de goles con un solo club. La marca es conservada por el exfutbolista brasileño Pelé, quien consiguió 643 dianas con el equipo Santos de Brasil.</p><br><p>Messi  marcó su primer gol con el Barcelona el 1 de mayo de 2005 y suma 879 partidos a lo largo de su carrera. El delantero de 33 años tiene contrato con el club catalán hasta junio de 2021. </p>',
      tipo: 'Verdadera',
      feedback_correcto: '¡Has acertado! Esa información era confiable, pues contaba con las características como: datos precisos, contextualizados y redacción correcta. ',
      feedback_incorrecto: 'Dejaste ir una noticia verdadera. Elementos como una redacción correcta, el uso de datos precisos y relacionados al contexto, son señales de que puedes confiar en la noticia que estás leyendo. '
    },
    9: {
      titulo: '14% de los alimentos se pierden antes de llegar a los establecimientos  ',
      subtitulo: '',
      body: '<p>En el Día Internacional de Concienciación sobre la Pérdida y el Desperdicio de Alimentos, la Organización de las Naciones Unidas para la Alimentación y la Agricultura (FAO) aseguró que la pérdida y el desperdicio de alimentos a nivel mundial está generando una problemática en el acceso a alimentos nutritivos e inocuos, un aumento de la emisión de gases de efecto invernadero y el desaprovechamiento de recursos naturales.  </p><br><p>África Subsahariana y Asia Meridional representan respectivamente el 14% y el 20,7% de las pérdidas de alimentos, mientras que en países como Nueva Zelanda y Australia es del 5,8%, lo que significa que los territorios en vía de desarrollo son más propensos al desperdicio de los mismos.   </p><br><p>Tubérculos, frutas y hortalizas, raíces y cultivos oleaginosos y los productos de origen animal son los que más se descartan o eliminan al momento de su venta debido a estrictas normas de calidad o a la incomprensión de su fecha de vencimiento.   </p><br><p>La FAO concluye que el 14% de los alimentos, valorados en 400.000 millones de dólares anualmente, se pierden y se desperdician y además generan aproximadamente 1,5 gigatoneladas de dióxido de carbono (CO2).  </p>',
      tipo: 'Verdadera',
      feedback_correcto: '¡Has acertado! Esa información era confiable, pues contaba con las características como: datos precisos, contextualizados y redacción correcta. ',
      feedback_incorrecto: 'Dejaste ir una noticia verdadera. Elementos como una redacción correcta, el uso de datos precisos y relacionados al contexto, son señales de que puedes confiar en la noticia que estás leyendo. '
    },
    10: {
      titulo: 'Apple Watch Series 6 medirá el oxígeno de la sangre  ',
      subtitulo: '',
      body: '<p>Apple presentó un modelo renovado del popular Apple Watch Series, el cual podrá medir el oxígeno en la sangre. A través de un evento virtual, desde su sede en Cupertino (California), la empresa estadounidense dio a conocer la nueva versión dispositivo que cumplió cinco años en el mercado.  </p><br><p>Jeff Williams, jefe de operaciones de Apple, afirmó: “El Apple Watch Series 6 redefine por completo lo que un reloj puede hacer (...) con nuevas y avanzadas funcionalidades, incluyendo un sensor y una app para medir el nivel de oxígeno en la sangre”.  </p><br><p>Apple trabajará con la Universidad de California Irvine y con la compañía de seguros Anthem para investigar cómo las personas que padecen asma podrían llegar a mejorar su condición de vida con dispositivos como el Apple Watch Series 6.  </p><br><p>El reloj, que cuenta con un procesador S6 que mejorará su funcionamiento, se podrá conseguir desde 399 dólares.  </p>',
      tipo: 'Verdadera',
      feedback_correcto: '¡Has acertado! Esa información era confiable, pues contaba con las características como: datos precisos, contextualizados y redacción correcta. ',
      feedback_incorrecto: 'Dejaste ir una noticia verdadera. Elementos como una redacción correcta, el uso de datos precisos y relacionados al contexto, son señales de que puedes confiar en la noticia que estás leyendo. '
    },
    11: {
      titulo: 'Hamilton supera el récord de victorias de Schumacher en la F1  ',
      subtitulo: '',
      body: '<p>Lewis Hamilton, de la escudería Mercedes, consiguió su triunfo número 92 en el campeonato de Fórmula Uno y superó a Michael Schumacher (91) como el piloto con más victorias en la máxima categoría del automovilismo.   </p><br><p>El corredor británico ganó el Gran Premio de Portugal, en el Autódromo Internacional de Algarve, una semana después de haber conseguido el primer puesto en el Gran Premio de Eifel, en Alemania.   </p><br><p>Lewis, de 35 años, manifestó en una publicación en su cuenta oficial de Instagram: “No podría estar aquí sin mi equipo (...) no importa cuántos domingos hagamos esto, siempre es un desafío (...) estoy pensando en Michael, siempre tendré la mayor admiración y respeto por ti”.  </p><br><p>Si Hamilton consigue el campeonato en 2020 igualará los siete títulos de Michael Schumacher en la Fórmula Uno.   </p>',
      tipo: 'Verdadera',
      feedback_correcto: '¡Has acertado! Esa información era confiable, pues contaba con las características como: datos precisos, contextualizados y redacción correcta. ',
      feedback_incorrecto: 'Dejaste ir una noticia verdadera. Elementos como una redacción correcta, el uso de datos precisos y relacionados al contexto, son señales de que puedes confiar en la noticia que estás leyendo. '
    },
    12: {
      titulo: 'Los deportistas que han ganado más dinero en el 2020 ',
      subtitulo: '',
      body: '<p>La revista Forbes, especializada en negocios y finanzas, dio a conocer la lista de los 100 atletas que más han obtenido ingresos en el año. El ránking lo encabeza Roger Federer, quien mantiene ganancias por 106 millones de dólares. Federer es el primer tenista en liderar la lista y ha podido consolidar su fortuna en lo que va del 2020, gracias a acuerdos con grandes empresas como Credit Suisse Group y Mercedes-Benz.   </p><br><p>En segundo lugar se encuentra el futbolista portugués Cristiano Ronaldo con beneficios de 105 millones de dólares. Le sigue el también futbolista Lionel Messi, con 104 millones de dólares. El top 5 lo completan el futbolista Neymar con 95 millones de dólares y el jugador de baloncesto, Lebron James, con 88 millones de dólares.  </p><br><p>Los 100 atletas de la lista suman ganancias de 3.600 millones de dólares en el año, sin embargo esta cifra representa una disminución del 9% frente al 2019. Es importante señalar que solo dos mujeres entran en el top-100 de deportistas con mayores beneficios. La tenista Naomi Osaka ocupa el puesto 29 con 37 millones de dólares y su colega Serena Williams está en el lugar 33 con ingresos de 36 millones de dólares. </p><br><p>*Ingrese a la página oficial de la Revista Forbes para ver el listado completo.   </p>',
      tipo: 'Verdadera',
      feedback_correcto: '¡Bien hecho! Esta fue una noticia verdadera, has hecho bien en confiar en esta información. Recuerda verificar las fuentes dentro de las noticias.',
      feedback_incorrecto: 'Esta era información verdadera. Recuerda que puedes confiar en información donde puedas identificar claramente los datos y fuentes que validan la noticia.'
    },
    13: {
      titulo: 'Dua Lipa rompe récords en Spotify  ',
      subtitulo: '',
      body: '<p>La cantante británica, de ascendencia kosovar, se convirtió en la primera artista femenina en superar las mil millones de reproducciones, con cuatro canciones (New Rules, One Kiss, Don’t Start Now, IDGAF), en la plataforma de streaming Spotify.   </p><br><p>Dua Lipa lanzó su último álbum Future Nostalgia el 27 de marzo y también alcanzó el récord de que su disco fuera el más reproducido en el primer día de lanzamiento por una cantante británica a nivel mundial, en el Reino Unido y en Estados Unidos. </p><br><p>En su cuenta de Instagram, Dua Lipa expresó: “Tengo que celebrar estas pequeñas victorias (...) gracias a todos y cada uno de ustedes que ha escuchado mi música”. La cantante de 25 años ganó el premio Grammy como Mejor Artista Nuevo en el 2019 y se proyecta como una de las referentes del pop a nivel mundial.  </p>',
      tipo: 'Verdadera',
      feedback_correcto: '¡Bien hecho! Esta fue una noticia verdadera, has hecho bien en confiar en esta información. Recuerda verificar las fuentes dentro de las noticias.',
      feedback_incorrecto: 'Esta era información verdadera. Recuerda que puedes confiar en información donde puedas identificar claramente los datos y fuentes que validan la noticia.'
    },
    14: {
      titulo: 'Un gato, el nuevo geoglifo de Nazca  ',
      subtitulo: '',
      body: '<p>El Ministerio de Cultura de Perú confirmó el hallazgo de una nueva figura en la Pampa de Nasca. Se trata de un felino de 37 metros de largo, con trazos entre 30 y 40 centímetros de ancho, que fue descubierto durante el trabajo que se realiza en el Mirador Natural de la zona.  </p><br><p>La figura estuvo a punto de desaparecer debido a la erosión, por su ubicación en una ladera del lugar, pero después de un proceso de limpieza y conservación, la entidad estatal de Perú dio a conocer las características del geoglifo.  </p><br><p>Desde 1994, la Organización de las Naciones Unidas para la Educación, la Ciencia y la Cultura (UNESCO), declaró Las Líneas y Geoglifos de Nasca y Palpa como Patrimonio Mundial. Este legado cultural de las sociedades prehispánicas, según los investigadores, se llevó a cabo aproximadamente entre el año 100 y el 300 a.C. </p>',
      tipo: 'Verdadera',
      feedback_correcto: '¡Has acertado! Esa información era confiable, pues contaba con las características como: datos precisos, contextualizados y redacción correcta. ',
      feedback_incorrecto: 'Dejaste ir una noticia verdadera. Elementos como una redacción correcta, el uso de datos precisos y relacionados al contexto, son señales de que puedes confiar en la noticia que estás leyendo. '
    },
    15: {
      titulo: 'California alcanza un récord de incendios en 2020 ',
      subtitulo: '',
      body: '<p>El estado de California, ubicado en el occidente de Estados Unidos, suma alrededor de ocho mil incendios en la temporada, que han devastado 1.62 millones de hectáreas y han afectado a más de 50.000 personas.  </p><br><p>El Departamento Forestal y de Protección contra Incendios del estado (Calfire), que registra los incendios del estado desde 1933, alertó acerca del número de hectáreas que se han quemado en lo que va del año y advirtió a la ciudadanía tomar precauciones debido al fenómeno natural.  </p><br><p>Los incendios han generado grandes capas de humo e incluso el nivel “peligroso” en el Índice de Calidad del Aire, lo que podría desencadenar en enfermedades respiratorias, cardiacas e inmunológicas. Se estima que en noviembre aumente el número de áreas afectadas, debido a que esta es la época del año en la que más se registran quemas.</p>',
      tipo: 'Verdadera',
      feedback_correcto: '¡Has acertado! Esa información era confiable, pues contaba con las características como: datos precisos, contextualizados y redacción correcta. ',
      feedback_incorrecto: 'Dejaste ir una noticia verdadera. Elementos como una redacción correcta, el uso de datos precisos y relacionados al contexto, son señales de que puedes confiar en la noticia que estás leyendo. '
    },
    16: {
      titulo: 'Baby Shark, el video con más reproducciones en Youtube  ',
      subtitulo: '',
      body: '<p>Youtube anunció que Baby Shark superó a la canción Despacito como el video con más visualizaciones de la plataforma. La canción surcoreana llegó a 7.044 millones de vistas y superó al tema de los puertorriqueños, Luis Fonsi y Daddy Yankee, que contaba con 7.038 millones de reproducciones.  </p><br><p>La canción infantil, interpretada por la cantante Hope Segoine, fue compartida en Youtube el 17 de junio de 2006 y ha tenido gran acogida en los niños. Además se han realizado decenas de versiones de la misma; incluso este año, Luis Fonsi colaboró en una de ellas y en el video se observa una ilustración de él y un tiburón.  </p><br><p>Cabe resaltar que la popularidad del tema generó que apareciera en la lista del top-100 de canciones de la lista Billboard, el ránking de los sencillos musicales más vendidos en Estados Unidos.  </p>',
      tipo: 'Verdadera',
      feedback_correcto: '¡Bien hecho! Esta fue una noticia verdadera, has hecho bien en confiar en esta información. Recuerda verificar las fuentes dentro de las noticias.',
      feedback_incorrecto: 'Esta era información verdadera. Recuerda que puedes confiar en información donde puedas identificar claramente los datos y fuentes que validan la noticia.'
    },
    17: {
      titulo: '1,92 billones de dólares gastaron los países en armamento ',
      subtitulo: '',
      body: '<p>Un informe del Instituto Internacional para la Paz de Estocolmo (SIPRI) reveló que en el 2019 los países industrializados aumentaron su gasto militar 3,6% en comparación con el 2018.   </p><br><p>Estados Unidos, con 732.000 millones de dólares, lidera el top-5 de los países que más desembolsaron dinero en armas, lo que representa el 38% del gasto mundial. Le siguen China y la India con 261.000 y 71.100 millones de dólares, respectivamente. La lista la completan Rusia con 65.100 y Arabia Saudí con 61.900 millones de dólares.  </p><br><p>Tarja Cronberg, miembro del SIPRI, aseguró que el crecimiento de estas cifras se debe a la rivalidad entre grandes potencias como Estados Unidos, China y Rusia. Brasil fue el país de América Latina que más gastó en elementos armamentísticos con 26.900 millones de dólares. </p>',
      tipo: 'Verdadera',
      feedback_correcto: '¡Bien hecho! Esta fue una noticia verdadera, has hecho bien en confiar en esta información. Recuerda verificar las fuentes dentro de las noticias.',
      feedback_incorrecto: 'Esta era información verdadera. Recuerda que puedes confiar en información donde puedas identificar claramente los datos y fuentes que validan la noticia.'
    },
    18: {
      titulo: 'La consola PlayStation 5 ya está en el mercado ',
      subtitulo: '',
      body: '<p>Sony confirmó que la consola PlayStation 5 ya puede ser adquirida en todo el mundo. Después del lanzamiento inicial en Estados Unidos, Japón y México, la compañía japonesa puso a la venta la consola que se puede conseguir en su versión estándar con un precio de 499 dólares y en su versión digital sin lector de discos en 399 dólares.  </p><br><p>Cabe resaltar que la consola será compatible con videojuegos de PlayStation 4, podrá reproducir imágenes en resolución de 8 y 4K y tendrá 825 gigabytes de almacenamiento. Además sus controles contarán con respuesta táctil y efectos de gatillo dinámicos. Sony, además, reveló que trabaja en nuevos videojuegos como Harry Potter: Hogwarts Legacy, Fortnite y  Call of Duty: Black Ops Cold War. </p><br><p> </p>',
      tipo: 'Verdadera',
      feedback_correcto: '¡Has acertado! Esa información era confiable, pues contaba con las características como: datos precisos, contextualizados y redacción correcta. ',
      feedback_incorrecto: 'Dejaste ir una noticia verdadera. Elementos como una redacción correcta, el uso de datos precisos y relacionados al contexto, son señales de que puedes confiar en la noticia que estás leyendo. '
    },
    19: {
      titulo: 'Acceso a herramientas digitales: un reto para los estudiantes de América Latina ',
      subtitulo: '',
      body: '<p>La Organización para la Cooperación y el Desarrollo Económico (OCDE) señaló las desigualdades entre diferentes países en el acceso a computadores por parte de sus estudiantes.  </p><br><p>De acuerdo al programa internacional para la Evaluación de Estudiantes o informe PISA, países como Marruecos, Kosovo y Filipinas tienen la menor cantidad de computadores escolares conectados a Internet.  </p><br><p>En el caso de los países de América Latina: Perú, Panamá, Argentina y Colombia se encuentran en el top-10 de naciones con la cifra más baja de equipos electrónicos escolares con acceso a Internet.   </p><br><p>Por su parte, Dinamarca, Suecia y Finlandia son los territorios en los que el 100% de los estudiantes tienen la posibilidad de contar con un computador conectado a la red. La OCDE asocia el acceso a esta herramienta con mejor desempeño de lectura.  </p>',
      tipo: 'Verdadera',
      feedback_correcto: '¡Has acertado! Esa información era confiable, pues contaba con las características como: datos precisos, contextualizados y redacción correcta. ',
      feedback_incorrecto: 'Dejaste ir una noticia verdadera. Elementos como una redacción correcta, el uso de datos precisos y relacionados al contexto, son señales de que puedes confiar en la noticia que estás leyendo. '
    },
    20: {
      titulo: 'Los residuos de plástico podrían triplicarse en el 2040 ',
      subtitulo: '',
      body: '<p>Una investigación publicada en la revista científica estadounidense Science asegura que los residuos de plástico podrían aumentar si no se toman medidas necesarias por parte de los gobiernos, industrias y población en general.  </p><br><p>Cada año se vierten a la tierra 30 millones de toneladas de plástico, 50 millones de toneladas se queman a cielo abierto y 11 millones de toneladas terminan en el océano, se prevé que esta última cifra aumente a 29 millones de toneladas en el 2040.   </p><br><p>Además del daño ambiental, anualmente el uso del plástico genera más gastos, teniendo en cuenta que botellas o envolturas solo se utilizan una vez. El estudio hace un llamado a manejar la problemática desde diferentes prioridades, de acuerdo a cada zona geográfica y categorías de plástico, con el fin de que se pueda observar un cambio en el año 2040.   </p>',
      tipo: 'Verdadera',
      feedback_correcto: '¡Has acertado! Esa información era confiable, pues contaba con las características como: datos precisos, contextualizados y redacción correcta. ',
      feedback_incorrecto: 'Dejaste ir una noticia verdadera. Elementos como una redacción correcta, el uso de datos precisos y relacionados al contexto, son señales de que puedes confiar en la noticia que estás leyendo. '
    },
    21: {
      titulo: '¿Cómo evitar el uso de plástico?',
      subtitulo: '',
      body: '<p>Según el Fondo Mundial para la Naturaleza (WWF), cada año se vierten a los océanos 11 millones de toneladas de plástico. Sin embargo, reduciendo el uso de este material podemos contribuir a que esa cifra disminuya. \n \n- Lleva contigo una taza y cubiertos reutilizables al momento de comprar una bebida o una comida cuando te encuentres fuera de tu casa.  \n- Si realizas una compra carga tus propias bolsas de tela o de materiales reciclables. \n- Evita comprar bebidas y alimentos empacados con plástico. \n- Utiliza elementos de aseo que no requieran plástico (cepillos de bambú, panties absorbentes, afeitadoras con mango de bambú, entre otros).  </p>',
      tipo: 'Verdadera',
      feedback_correcto: '¡Los has hecho bien! Siempre que algún portal de información online te brinde información o consejos, asegúrate de que cite a fuentes confiables de información, como organizaciones expertas en la temática',
      feedback_incorrecto: 'Si la información que te están presentando cuenta con el respaldo de organizaciones reconocidas, puedas confiar en la publicación y sus datos. '
    },
    22: {
      titulo: 'Consejos para disminuir el consumo de luz en los hogares ',
      subtitulo: '',
      body: '<p>El Programa de las Naciones Unidas para el Desarrollo (PNUD) asegura que los modelos no sostenibles de consumo de energía generan daños en el medio ambiente. No obstante, desde los hogares se pueden seguir ciertos parámetros para disminuir el gasto de luz y así luchar contra el cambio climático.  </p><br><p>- Aprovechar la luz natural para actividades como lectura, trabajo y ocio.  \n- Ajustar el brillo de las pantallas de celulares y computadores con el fin de que utilicen menos cantidad de batería.  \n- Desconectar los aparatos electrónicos que no estén en uso. \n- Utilizar herramientas como ventiladores, aire acondicionado y calefactores solo en casos necesarios y no de manera habitual.  \n- Aprovechar la lavadora para la mayor cantidad de prendas y así evitar su uso de manera frecuente.  </p>',
      tipo: 'Verdadera',
      feedback_correcto: '¡Los has hecho bien! Siempre que algún portal de información online te brinde información o consejos, asegúrate de que cite a fuentes confiables de información, como organizaciones expertas en la temática',
      feedback_incorrecto: 'Si la información que te están presentando cuenta con el respaldo de organizaciones reconocidas, puedas confiar en la publicación y sus datos. '
    },
    23: {
      titulo: 'Una buena alimentación ayuda a evitar enfermedades  ',
      subtitulo: '',
      body: '<p>De acuerdo con la Organización Mundial de la Salud (OMS), las dietas saludables permiten que los seres humanos fortalezcan la protección frente a enfermedades no transmisibles como la diabetes o las afectaciones cardíacas.   </p><br><p>La OMS recomienda que la ingesta de calorías de un adulto debe estar en equilibrio con el gasto calórico. Las grasas no deben superar el 30% y se recomienda ingerir grasas sanas presentes en alimentos como almendras, aguacates y pescados. El consumo de azúcar no debe ser mayor al 10% de todo lo que se come en el día y es preferible que se opte por el origen natural como la miel y las frutas.  </p><br><p>La organización sugiere consumir menos de 5 gramos al día de sal, con el fin de prevenir la hipertensión y los problemas cardiovasculares e incluir en la dieta cereales integrales como la avena. Cabe resaltar que estas recomendaciones son generales y la alimentación de cada persona depende de diversos factores como sexo, edad, hábitos de actividad física, contexto cultural, entre otros.  </p>',
      tipo: 'Verdadera',
      feedback_correcto: '¡Los has hecho bien! Siempre que algún portal de información online te brinde información o consejos, asegúrate de que cite a fuentes confiables de información, como organizaciones expertas en la temática',
      feedback_incorrecto: 'Si la información que te están presentando cuenta con el respaldo de organizaciones reconocidas, puedas confiar en la publicación y sus datos. '
    },
    24: {
      titulo: 'Recomendaciones para la seguridad de los niños en internet',
      subtitulo: '',
      body: '<p>El Fondo de las Naciones Unidas para la Infancia (UNICEF) compartió cinco recomendaciones para mantener seguros a los niños y niñas mientras navegan en Internet.  \n - Comunicación abierta entre cuidadores y los niños. \n-Fomento de hábitos seguros en línea y el uso responsable del Internet. \n-Compartir tiempo con los niños y niñas con el fin de generar empatía en las interacciones virtuales. \n-Utilizar herramientas como control parental o safesearch para garantizar que accedan a contenidos de acuerdo a su edad. \n-Bloquear, reportar y denunciar situaciones que pongan en riesgo a los niños y niñas. </p>',
      tipo: 'Verdadera',
      feedback_correcto: '¡Los has hecho bien! Siempre que algún portal de información online te brinde información o consejos, asegúrate de que cite a fuentes confiables de información, como organizaciones expertas en la temática',
      feedback_incorrecto: 'Si la información que te están presentando cuenta con el respaldo de organizaciones reconocidas, puedas confiar en la publicación y sus datos. '
    },
    25: {
      titulo: '5 herramientas digitales para el cuidado de la salud mental',
      subtitulo: '',
      body: '<p>La Organización Mundial de la Salud (OMS) define a la salud mental como un componente integral y esencial de salud que combina el bienestar físico, mental y social. Así como se fortalece la parte física, es recomendable hacer uso de herramientas para contribuir a la salud mental. Estas cinco aplicaciones te pueden ayudar con ese fin: </p><br><p>-Headspace: para aprender a meditar. \n-Calm: meditación y lectura de cuentos para antes de dormir. \n-Moodpath: herramientas de ayuda en el manejo de la ansiedad y el estrés. \n-Youper: técnicas de respiración para el manejo de las emociones. \n-Rootd: ejercicios del manejo de la respiración y el estrés cuando se presentan ataques de pánico o ansiedad. </p><br><p>Es importante resaltar que estas aplicaciones son herramientas útiles para la contribuir a la paz mental, pero no deben utilizarse como un sustituto de profesionales de esta área como los psicólogos y terapeutas.  </p>',
      tipo: 'Verdadera',
      feedback_correcto: '¡Los has hecho bien! Siempre que algún portal de información online te brinde información o consejos, asegúrate de que cite a fuentes confiables de información, como organizaciones expertas en la temática',
      feedback_incorrecto: 'Si la información que te están presentando cuenta con el respaldo de organizaciones reconocidas, puedas confiar en la publicación y sus datos. '
    },
    26: {
      titulo: 'Freidora de aire 4.2 litros ',
      subtitulo: 'Lina Rivera',
      body: '<p>La olla Airfryer me ha parecido una gran inversión. Es un elemento indispensable para la cocina, mejora los tiempos de cocción de los alimentos, es muy saludable porque no requiere de aceite y, además, tiene gran capacidad. Su precio es acorde a sus beneficios y, sin duda, la recomendaría.  </p><br><p></p>',
      tipo: 'Verdadera',
      feedback_correcto: '¡Continua así! Las reseñas comerciales suelen ser un espacio en donde abunda información falsa. Asegúrate de identificar el nombre del autor de la nota y que brinde información precisa acerca del producto en cuestión.',
      feedback_incorrecto: 'Si la información que te están presentando cuenta con el respaldo de organizaciones reconocidas, puedas confiar en la publicación y sus datos. '
    },
    27: {
      titulo: 'Curso de inglés en línea',
      subtitulo: 'Ramiro García',
      body: '<p>Este curso me ayudó muchísimo a mejorar mis habilidades en la lectura y escritura en inglés. Estaba un poco escéptico por la metodología no presencial, pero con las herramientas de internet y el acompañamiento de los profesores logré un avance significativo en la aplicación del inglés en mi vida cotidiana. Recomiendo 100% el curso y las clases de lectura científica y técnica.  </p><br><p></p>',
      tipo: 'Verdadera',
      feedback_correcto: '¡Los has hecho bien! Siempre que algún portal de información online te brinde información o consejos, asegúrate de que cite a fuentes confiables de información, como organizaciones expertas en la temática',
      feedback_incorrecto: 'Aunque en las reseñas comerciales muchas veces encontrarás información falsa, que pretende formar tu opinión respecto a un producto o servicio, es buena señal si la reseña cuenta con detalles del producto y nombre del autor de esta.'
    },
    28: {
      titulo: ' Champú vegano ',
      subtitulo: 'Sara Ruiz',
      body: '<p>Desde hace un tiempo he querido cambiar mis hábitos alimenticios y de consumo para hacerlos mucho más sostenibles. Compré el champú vegano, que no es testeado en animales, pero al parecer mi cuero cabelludo no lo asimiló de buena manera y he presentado resequedad. Los productos como el jabón y la crema son muy buenos, pero el champú definitivamente no me funcionó.  </p>',
      tipo: 'Verdadera',
      feedback_correcto: '¡Los has hecho bien! Siempre que algún portal de información online te brinde información o consejos, asegúrate de que cite a fuentes confiables de información, como organizaciones expertas en la temática',
      feedback_incorrecto: 'Aunque en las reseñas comerciales muchas veces encontrarás información falsa, que pretende formar tu opinión respecto a un producto o servicio, es buena señal si la reseña cuenta con detalles del producto y nombre del autor de esta.'
    },
    29: {
      titulo: ' iPhone 12 ProMax 265 GB',
      subtitulo: 'Diego Mayorga',
      body: '<p>Me inscribí en la preventa del iPhone 12 ProMax y ¡valió toda la pena! Es el mejor producto que ha podido sacar Apple, las fotos y los videos tienen una calidad excepcional. En las fotos se pueden observar texturas, colores muy reales y la luz precisa. Es el mejor aparato electrónico del mercado.  </p><br><p></p>',
      tipo: 'Verdadera',
      feedback_correcto: '¡Los has hecho bien! Siempre que algún portal de información online te brinde información o consejos, asegúrate de que cite a fuentes confiables de información, como organizaciones expertas en la temática',
      feedback_incorrecto: 'Aunque en las reseñas comerciales muchas veces encontrarás información falsa, que pretende formar tu opinión respecto a un producto o servicio, es buena señal si la reseña cuenta con detalles del producto y nombre del autor de esta.'
    },
    30: {
      titulo: ' Kit de pastelería ',
      subtitulo: 'Laura Roldán',
      body: '<p>Compré el kit de pastelería para empezar a realizar platos básicos en el día a día. Sin embargo, los materiales de las piezas son muy endebles, parecen hasta de juguete. Los moldes de muffins no funcionaron para el horno y las mangas pasteleras son muy débiles. Definitivamente no lo recomiendo.  </p>',
      tipo: 'Verdadera',
      feedback_correcto: '¡Los has hecho bien! Siempre que algún portal de información online te brinde información o consejos, asegúrate de que cite a fuentes confiables de información, como organizaciones expertas en la temática',
      feedback_incorrecto: 'Aunque en las reseñas comerciales muchas veces encontrarás información falsa, que pretende formar tu opinión respecto a un producto o servicio, es buena señal si la reseña cuenta con detalles del producto y nombre del autor de esta.'
    },
    31: {
      titulo: 'Explosión en Kabul deja siete personas heridas ',
      subtitulo: '',
      body: '<p>Una explosión al este de Kabul ocasionó heridas en siete personas, quienes tuvieron que ser trasladados al hospital estatal de la capital afgana. Hasta el momento no se conoce al autor del atentado, sin embargo todas las hipótesis apuntan a un ataque terrorista.   </p><br><p>En la actualidad, el control de Kabul se disputa  entre grandes grupos radicales de Medio Oriente. Casi a diario, se presentan atentados hacia la población civil, hospitales y escuelas.  </p>',
      tipo: 'Falsa',
      feedback_correcto: '¡Has tomado una buena decisión! Encontrarás que hay noticias en internet basadas en hechos que no son actuales o descontextualizados con la realidad actual.',
      feedback_incorrecto: 'Has confiado en información que no es del todo cierta. Encontrarás publicaciones en donde te presentarán hechos reales, pero antiguos o fuera de contexto, seleccionados con el único objetivo de confundirte o hacerte creer en información falsa.'
    },
    32: {
      titulo: 'Flamencos llegan al Río Magdalena ',
      subtitulo: '',
      body: '<p>Decenas de flamencos fueron vistos hoy en el Río Magdalena. La fuente hídrica, que atraviesa más de siete departamentos colombianos y que tiene aproximadamente 1.528 kilómetros, alberga nuevos huéspedes. Al parecer el arribo de estos animales se hizo durante la madrugada, por lo que los habitantes que viven en sectores aledaños no pudieron percatarse de su llegada. </p><br><p>Los flamencos son aves que pueden alcanzar un metro de longitud y un peso entre uno y tres kilos, se alimentan de microorganismos que encuentran en el agua y es común verlos en grupo.  </p>',
      tipo: 'Falsa',
      feedback_correcto: '¡Has tomado una buena decisión! Encontrarás que hay noticias en internet basadas en hechos que no son actuales o descontextualizados con la realidad actual.',
      feedback_incorrecto: 'Has confiado en información que no es del todo cierta. Encontrarás publicaciones en donde te presentarán hechos reales, pero antiguos o fuera de contexto, seleccionados con el único objetivo de confundirte o hacerte creer en información falsa.'
    },
    33: {
      titulo: ' Volcán Galeras hace erupción en la mañana ',
      subtitulo: '',
      body: '<p>El volcán Galeras ubicado en Pasto, Colombia, hizo erupción a las 8:07 a.m. hora local y hasta el momento no se presentan daños, ni víctimas. El alcalde de la capital de Nariño hizo un llamado a la ciudadanía para mantener la calma y contar con provisiones en caso de una evacuación de las zonas aledañas al Galeras.   </p><br><p>El volcán ha presentado una serie de explosiones en los últimos años, sin embargo, una de las más recordadas fue la 1993, en la que perdieron la vida nueve personas que se encontraban explorando el lugar.  </p>',
      tipo: 'Falsa',
      feedback_correcto: '¡Has tomado una buena decisión! Encontrarás que hay noticias en internet basadas en hechos que no son actuales o descontextualizados con la realidad actual.',
      feedback_incorrecto: 'Has confiado en información que no es del todo cierta. Encontrarás publicaciones en donde te presentarán hechos reales, pero antiguos o fuera de contexto, seleccionados con el único objetivo de confundirte o hacerte creer en información falsa.'
    },
    34: {
      titulo: 'Un perro rescata a una familia después del terremoto de México ',
      subtitulo: '',
      body: '<p>Un perro del cuerpo de bomberos de México rescató solo a una familia en Ciudad de México, después del terremoto ocurrido este martes en la capital del país norteamericano. El labrador encontró a la familia, quienes se encuentra en buen estado de salud, luego de olfatear la zona durante más de dos días.  </p><br><p>México sufrió este martes un terremoto de 7.1 en la escala de Richter con epicentro en la ciudad de Cuernavaca. Hasta el momento, las víctimas ascienden a 300 y el número de heridos supera los 2.000. </p>',
      tipo: 'Falsa',
      feedback_correcto: '¡Has tomado una buena decisión! Encontrarás que hay noticias en internet basadas en hechos que no son actuales o descontextualizados con la realidad actual.',
      feedback_incorrecto: 'Has confiado en información que no es del todo cierta. Encontrarás publicaciones en donde te presentarán hechos reales, pero antiguos o fuera de contexto, seleccionados con el único objetivo de confundirte o hacerte creer en información falsa.'
    },
    35: {
      titulo: 'Huracán María inunda la principal carretera de San Juan ',
      subtitulo: '',
      body: '<p>El huracán María, de categoría 5, arrasó con la principal carretera de San Juan. El evento meteorológico ha sido uno de los más fuertes que se han presentado en la zona en los últimos años. La carretera 2, como es conocido este trazado, no cuenta con un buen sistema de drenaje y el agua inundó hasta un 60% del tramo que conecta San Juan con Aguadilla.   </p><br><p>El fenómeno natural marca el inicio de la temporada de huracanes del 2017, un época que se caracteriza por la formación de tormentas tropicales en el Caribe y en el Pacífico y que afecta principalmente a los países centroamericanos, a México y a Estados Unidos.  </p>',
      tipo: 'Falsa',
      feedback_correcto: '¡Has tomado una buena decisión! Encontrarás que hay noticias en internet basadas en hechos que no son actuales o descontextualizados con la realidad actual.',
      feedback_incorrecto: 'Has confiado en información que no es del todo cierta. Encontrarás publicaciones en donde te presentarán hechos reales, pero antiguos o fuera de contexto, seleccionados con el único objetivo de confundirte o hacerte creer en información falsa.'
    },
    36: {
      titulo: 'Hacer cardio con música electrónica ayuda a bajar de peso ',
      subtitulo: '',
      body: '<p>Una investigación de una reconocida revista estadounidense reveló que cuando se realizan ejercicios de fortalecimiento cardiovascular mientras se escucha música electrónica hay más probabilidades de quemar grasa corporal, sobre todo en la zona abdominal.   </p><br><p>Según el informe, este género musical fomenta la energía y las ganas de hacer más cardio por lo que se queman más calorías y así se puede bajar de peso. Un trabajo, de aproximadamente 20 minutos de cardio escuchando música electrónica, generará la disminución de 300 calorías. Normalmente para alcanzar esta cifra se requiere una hora de caminata.   </p><br><p>El estudio se realizó con 20 personas de un gimnasio en la ciudad de Los Ángeles (California), quienes escucharon esta clase de música durante una semana, mientras se ejercitaban y vieron cambios a nivel corporal.  </p>',
      tipo: 'Falsa',
      feedback_correcto: '¡Has tomado una buena decisión! Encontrarás que hay noticias en internet basadas en hechos que no son actuales o descontextualizados con la realidad actual.',
      feedback_incorrecto: 'Has confiado en información que no es del todo cierta. Encontrarás publicaciones en donde te presentarán hechos reales, pero antiguos o fuera de contexto, seleccionados con el único objetivo de confundirte o hacerte creer en información falsa.'
    },
    37: {
      titulo: 'Cristiano Ronaldo se retirará del fútbol  ',
      subtitulo: '',
      body: '<p>El futbolista portugués Cristiano Ronaldo no jugará más al fútbol… pero solo por esta temporada, debido a una lesión que sufrió en su rodilla derecha. La estrella de Juventus no pudo completar el juego, el fin de semana pasado, frente al Milan y tendrá que permanecer alejado de las canchas durante seis semanas.  </p><br><p>Cristiano Ronaldo ha marcado 71 goles con el equipo italiano en 94 partidos, y se perfilaba como el máximo goleador de la temporada, sin embargo este título tendrá que esperar, por lo menos por una temporada más.  </p><br><p>El futbolista, de 35 años, será operado este viernes en Suiza y continuará la recuperación en Madeira, Portugal, su lugar de nacimiento, y se prevé que regrese a los entrenamientos con Juventus y con la selección nacional, a principios de año. </p>',
      tipo: 'Falsa',
      feedback_correcto: '¡Has tomado una buena decisión! Encontrarás que hay noticias en internet basadas en hechos que no son actuales o descontextualizados con la realidad actual.',
      feedback_incorrecto: 'Has confiado en información que no es del todo cierta. Encontrarás publicaciones en donde te presentarán hechos reales, pero antiguos o fuera de contexto, seleccionados con el único objetivo de confundirte o hacerte creer en información falsa.'
    },
    38: {
      titulo: 'Los viajes a la Luna ahora son una realidad ',
      subtitulo: '',
      body: '<p>¿Has pensado en tus próximas vacaciones? ¿Qué tal un viaje a la Luna? </p><br><p>Sí, leíste bien, a la Luna. Después del lanzamiento de naves espaciales en los últimos años, los viajes al espacio ya son posibles. Lo único que tienes que hacer es realizar un curso en línea que se ofrece de manera gratuita, solicitar una reserva por medio de la página de SpaceReal, y concursar por un cupo para el viaje, el cual cuesta alrededor de 500.000 dólares por persona.   </p><br><p>El proceso nunca había sido tan fácil, después de miles de pruebas y gracias al apoyo del gobierno norteamericano, el turismo en el espacio es una realidad. Allí, podrás disfrutar de la inigualable vista de la Tierra, visitarás la Estación Espacial más avanzada y además te encontrarás con el desafío de la gravedad en el cohete Phoenix 12, equipo diseñado únicamente para el turismo espacial.  </p><br><p>Anímate y disfruta de unas vacaciones de altura con un viaje personalizado y enfocado en ir más allá de los límites.</p>',
      tipo: 'Falsa',
      feedback_correcto: '¡Excelente! Hay información en internet creada y manipulada con el objetivo de convencerte para tomar ciertas decisiones, como comprar un bien o servicio, que en realidad puede tener una calidad inferior a la promocionada en internet.',
      feedback_incorrecto: '¡Analiza antes de confiar en la información! En internet muchas personas intentarán convencerte mediante mentiras o exageraciones para que tomes ciertas decisiones, como comprar ciertos productos online. Antes de decidir, tómate el tiempo de buscar fuentes alternativas.'
    },
    39: {
      titulo: 'El Puma Oriental reaparece después se haber sido declarado extinto ',
      subtitulo: '',
      body: '<p>El Puma Oriental, que había sido declarado, en 2018, por el Servicio de Pesca y Vida Silvestre de Estados Unidos, como un animal extinto fue visto este viernes en un terreno de Moscow, en el estado de Idaho, en EE.UU.  </p><br><p>Al parecer las fuertes nevadas de los últimos días en ese estado provocaron que el animal saliera del lugar donde se escondió los úitmos dos años, y pudiera ser visto por un deportista de la zona, quien al verlo alertó a las autoridades de la presencia del puma.  </p><br><p>El Puma Oriental es una especie que se caracteriza por su velocidad y letalidad al momento de cazar sus presas. En una semana puede puede llegar a alimentarse hasta de tres ciervos.</p>',
      tipo: 'Falsa',
      feedback_correcto: '¡Has tomado una buena decisión! Encontrarás que hay noticias en internet basadas en hechos que no son actuales o descontextualizados con la realidad actual.',
      feedback_incorrecto: 'Has confiado en información que no es del todo cierta. Encontrarás publicaciones en donde te presentarán hechos reales, pero antiguos o fuera de contexto, seleccionados con el único objetivo de confundirte o hacerte creer en información falsa.'
    },
    40: {
      titulo: 'La Tierra y Marte estarán más cerca que nunca y se podrían presentar desastres ',
      subtitulo: '',
      body: '<p>La Tierra y Marte no se encontraban tan cerca desde el 2018, este evento ocurre, en promedio, cada quince años. Este jueves volverán a encontrarse y el planeta rojo podrá ser divisado en todo el mundo. Desde que los científicos alertaron acerca de esta unión se presentan grandes conflictos entre países, disputas territoriales e incluso terremotos en fechas cercanas al acontecimiento.   </p><br><p>¿Por qué sucede esto? Marte es el planeta de la guerra y su influencia al estar más cerca de la Tierra puede causar grandes daños; su asociación no ha sido en vano. Hace dos años hubo un terremoto de 7.5 en la escala de Ritcher en Célebes, Indonesia y también el conflicto en Siria vivió momentos tensionantes por el control de diferentes zonas.  </p><br><p>Mañana cuando la Tierra esté más cerca de Marte podremos percatarnos de qué manera su energía puede determinar hasta el más mínimo choque entre personas de poder, una catástrofe ambiental, e incluso un conflicto bélico.  </p>',
      tipo: 'Falsa',
      feedback_correcto: '¡Excelente! Hay información en internet creada y manipulada con el objetivo de convencerte para tomar ciertas decisiones, como comprar un bien o servicio, que en realidad puede tener una calidad inferior a la promocionada en internet.',
      feedback_incorrecto: '¡Analiza antes de confiar en la información! En internet muchas personas intentarán convencerte mediante mentiras o exageraciones para que tomes ciertas decisiones, como comprar ciertos productos online. Antes de decidir, tómate el tiempo de buscar fuentes alternativas.'
    },
    41: {
      titulo: 'Crean vacuna contra las alergia a los gatos ',
      subtitulo: '  Por : adicta a los gatos ',
      body: '<p>Una empresa norteamericana creó la vacuna de la década: el antídoto contra la alergia hacia los gatos. Si tu mascota es de las que te hace estornudar todo el día, o tienes que mantener la ventana abierta por lo que puede generar en el ambiente, ya no tendrás que padecer más este problema; la vacuna para gatos ya está aquí.  </p><br><p>La compañía distribuirá la vacuna a fin de año y estará disponible en el mercado a partir de marzo del próximo año. Su costo será aproximadamente entre 20 y 60 dólares, todavía no se conoce qué clase de vacuna será y si el medicamento será por dosis, pero ya se tiene certeza que los efectos pueden llegar a ser inmediatos y la alergia desaparece en menos de dos horas.  </p><br><p>La vacuna fue producto de un estudio entre universidades de Estados Unidos y Reino Unido y contó con fondos del gobierno de Japón, uno de los países donde este animal es un emblema nacional.  </p>',
      tipo: 'Falsa',
      feedback_correcto: '¡Excelente! Hay información en internet creada y manipulada con el objetivo de convencerte para tomar ciertas decisiones, como comprar un bien o servicio, que en realidad puede tener una calidad inferior a la promocionada en internet.',
      feedback_incorrecto: '¡Analiza antes de confiar en la información! En internet muchas personas intentarán convencerte mediante mentiras o exageraciones para que tomes ciertas decisiones, como comprar ciertos productos online. Antes de decidir, tómate el tiempo de buscar fuentes alternativas.'
    },
    42: {
      titulo: 'Pueblo al norte de Canadá paga por vivir allí ',
      subtitulo: 'Por: viajero del mundo ',
      body: '<p>Banfield está pagando 4.000 dólares mensuales a cada persona extranjera que quiera asentarse en el pueblo ubicado al suroccidente de Canadá. Los requisitos son muy simples: permanecer allí mínimo dos años, contar con experiencia en oficios afines a la agricultura y la pesca, y estar dispuesto a invertir en el mismo lugar.  </p><br><p>Los beneficiarios además contarán con seguro médico para el núcleo familiar y un subsidio de transporte en caso de ser necesario. Banfield espera recibir más de 500.000 solicitudes cada mes, pero elegirá solo 500 personas al año para hacer parte de su comunidad. </p>',
      tipo: 'Falsa',
      feedback_correcto: '¡Excelente! Hay información en internet creada y manipulada con el objetivo de convencerte para tomar ciertas decisiones, como comprar un bien o servicio, que en realidad puede tener una calidad inferior a la promocionada en internet.',
      feedback_incorrecto: '¡Analiza antes de confiar en la información! En internet muchas personas intentarán convencerte mediante mentiras o exageraciones para que tomes ciertas decisiones, como comprar ciertos productos online. Antes de decidir, tómate el tiempo de buscar fuentes alternativas.'
    },
    43: {
      titulo: 'Vivir sin arrugas ya es un hecho  ',
      subtitulo: '',
      body: '<p>Una empresa francesa lanzó al mercado una crema antiarrugas que promete ser la revolución de este tipo de productos en el mundo. Con el uso de colágeno perlado y un ingrediente secreto de plantas la crema “not-age” promete eliminar las arrugas, y evitar que se generen más.  </p><br><p>La crema, que ya es todo un éxito en Europa, nació gracias a un estudio que realizó la compañía junto con una prestigiosa universidad de Escocia. A partir de allí, trabajaron durante dos años en la creación de toda la línea “not-age”, que se puede utilizar desde los 18.   </p><br><p>A nuestro país llegará el próximo mes, pero se puede solicitar un cupo de reserva de compra desde hoy. Tan solo hay que pagar 50 dólares como inversión inicial y después se desembolsa el restante cuando el producto se encuentre oficialmente a la venta en el país</p>',
      tipo: 'Falsa',
      feedback_correcto: '¡Excelente! Hay información en internet creada y manipulada con el objetivo de convencerte para tomar ciertas decisiones, como comprar un bien o servicio, que en realidad puede tener una calidad inferior a la promocionada en internet.',
      feedback_incorrecto: '¡Analiza antes de confiar en la información! En internet muchas personas intentarán convencerte mediante mentiras o exageraciones para que tomes ciertas decisiones, como comprar ciertos productos online. Antes de decidir, tómate el tiempo de buscar fuentes alternativas.'
    },
    44: {
      titulo: '¿Te gustaría ser embajador de ropa deportiva? ',
      subtitulo: '',
      body: '<p>Si eres un fanático de la ropa deportiva fashion y quieres ser parte de nuestra marca, ingresa a este enlace: digita tu número de cédula, tu celular y tu dirección. Te haremos llegar productos de excelente calidad cada mes para que puedas convertirte en nuestro embajador oficial. Recuerda que puedes etiquetarnos en tus historias de Instagram y compartir esta información con más personas para que la comunidad crezca cada día más.  </p>',
      tipo: 'Falsa',
      feedback_correcto: '¡Bien hecho! Siempre que te pidan información personal toma tus precauciones y consulta a las fuentes iniciales antes de dar tu información.',
      feedback_incorrecto: '¡Cuidado! cuando una publicación te pida tus datos personales debes ser precavido si no sabes quién usara esta información ni para qué. Siempre que te pidan datos personales, toma precauciones y consulta directamente a la fuente.'
    },
    45: {
      titulo: 'Conviertete en influencer de videojuegos  ',
      subtitulo: '',
      body: '<p>Si eres amante de los videojuegos y quieres tener en exclusiva el nuevo las últimas consolas, solo debes ingresar el número de tu tarjeta de crédito y recibirás accesorios, juegos y consolas por adelantado, incluso antes de que empiece la preventa en el país. ¡No lo dudes!  </p><br><p> Si eres amante de los videojuegos y quieres tener en exclusiva el nuevo las últimas consolas, solo debes ingresar el número de tu tarjeta de crédito y recibirás accesorios, juegos y consolas por adelantado, incluso antes de que empiece la preventa en el país. ¡No lo dudes!  </p>',
      tipo: 'Falsa',
      feedback_correcto: '¡Bien hecho! Siempre que te pidan información personal toma tus precauciones y consulta a las fuentes iniciales antes de dar tu información.',
      feedback_incorrecto: '¡Cuidado! cuando una publicación te pida tus datos personales debes ser precavido si no sabes quién usara esta información ni para qué. Siempre que te pidan datos personales, toma precauciones y consulta directamente a la fuente.'
    },
    46: {
      titulo: '¿Aún no tienes el último celular del mercado? ',
      subtitulo: '',
      body: '<p>Si nos entregas tu celular viejo como forma de pago, nosotros te entregamos uno de última tecología sin ningún cargo adicional. Lo único que tienes que hacer es ingresar tu número de cuenta de ahorros en este enlace, nosotros nos comunicaremos contigo y te haremos llegar el aparato en un rango de 20 días. ¡No lo pienses y anímate a tener el último celular! </p>',
      tipo: 'Falsa',
      feedback_correcto: '¡Bien hecho! Siempre que te pidan información personal toma tus precauciones y consulta a las fuentes iniciales antes de dar tu información.',
      feedback_incorrecto: '¡Cuidado! cuando una publicación te pida tus datos personales debes ser precavido si no sabes quién usara esta información ni para qué. Siempre que te pidan datos personales, toma precauciones y consulta directamente a la fuente.'
    },
    47: {
      titulo: '¿Cansado de dietas que no funcionan? ¡No busques más!',
      subtitulo: '',
      body: '<p>¿Has probado todas las dietas, ejercicios y métodos para bajar de peso y nada ha funcionado? No te preocupes, con esta crema para bajar grasa podrás tener el cuerpo que deseas. ¿Cómo conseguirla? Lo único que debes hacer es poner tu número de cédula, número de la tarjeta de crédito y los tres últimos últimos de la misma, y te enviaremos el producto de inmediato. Si no estás satisfecho, te devolveremos el dinero. </p>',
      tipo: 'Falsa',
      feedback_correcto: '¡Bien hecho! Siempre que te pidan información personal toma tus precauciones y consulta a las fuentes iniciales antes de dar tu información.',
      feedback_incorrecto: '¡Cuidado! cuando una publicación te pida tus datos personales debes ser precavido si no sabes quién usara esta información ni para qué. Siempre que te pidan datos personales, toma precauciones y consulta directamente a la fuente.'
    },
    48: {
      titulo: 'El carro de tus sueños puede ser suyo',
      subtitulo: '',
      body: '<p>Si estás cansado de caminar hacia tu lugar de trabajo, te invitamos a participar en el sorteo de una camioneta último modelo de la marca que desees. Solo debes llenar unos datos sencillos: tu número de teléfono, tu dirección y tu correo electrónico, nos pondremos en contacto contigo y estarás participando en la rifa del carro de tus sueños.  </p>',
      tipo: 'Falsa',
      feedback_correcto: '¡Bien hecho! Siempre que te pidan información personal toma tus precauciones y consulta a las fuentes iniciales antes de dar tu información.',
      feedback_incorrecto: '¡Cuidado! cuando una publicación te pida tus datos personales debes ser precavido si no sabes quién usara esta información ni para qué. Siempre que te pidan datos personales, toma precauciones y consulta directamente a la fuente.'
    },
    49: {
      titulo: 'Vivir en Estados Unidos nunca había sido tan fácil',
      subtitulo: '',
      body: '<p>¿Te gustaría emigrar a EEUU de manera legal? Nosotros te asesoramos en el proceso de visa, entrevista y trabajo en el país norteamericano. Lo único que debes hacer es consignar 300 dólares a nombre de Pepe Campos (0987654321) y nuestros abogados se pondrán en contacto contigo para iniciar el trámite. ¡EEUU te espera!  </p>',
      tipo: 'Falsa',
      feedback_correcto: '¡Bien hecho! Siempre que te pidan información personal toma tus precauciones y consulta a las fuentes iniciales antes de dar tu información.',
      feedback_incorrecto: '¡Cuidado! cuando una publicación te pida tus datos personales debes ser precavido si no sabes quién usara esta información ni para qué. Siempre que te pidan datos personales, toma precauciones y consulta directamente a la fuente.'
    },
    50: {
      titulo: 'Los nativos digitales nacen con manos más largas para manejar mejor los celulares  ',
      subtitulo: '',
      body: '<p>Un informe de una organización rusa asegura que los nativos digitales están naciendo con las manos más largas con el fin de poder manipular de manera más fácil los aparatos electrónicos.  </p><br><p>De acuerdo al proceso de evolución por selección natural, reseñado en el libro de Charles Darwin “El origen de las especies”, los seres humanos también se están adaptando a nuevas características del entorno.  </p><br><p>Los nativos digitales son las personas que nacieron después de 1995 y han crecido con el uso de nuevas tecnologías sin tener que pasar por procesos de aprendizaje de las mismas. En la actualidad, los niños aprenden a utilizar el celular de manera innata y sus características físicas para la manipulación de estos dispositivos, los hace casi una especie diferente.   </p>',
      tipo: 'Falsa',
      feedback_correcto: '¡Más allá del titular! Sigue así, entre más profundices en la información que lees, menos propenso serás a consumir y/o compartir información falsa. Evita quedarte únicamente con la información que ves en el titular.',
      feedback_incorrecto: '¡No creas únicamente en el titular! Encontrarás que es una de las prácticas más frecuentes para hacerte caer y compartir información falsa, mediante titulares que exageran y/o presentan la información de manera errónea. Antes de compartir información, lee el artículo completo.'
    },
    51: {
      titulo: 'Las empresas de videojuegos empezarán a cobrar cada vez que se utilice un videojuego  ',
      subtitulo: '',
      body: '<p>De acuerdo a una noticia publicada este jueves en un portal japonés de gammers, las empresas dueñas de las grandes consolas de videojuegos cobrarán cada vez que se use un disco de la marca de ellos. La noticia ha causado diferentes opiniones entre los fanáticos de los juegos virtuales.  </p><br><p>Por un lado, decenas de personas defienden que las empresas cobren porque así se limita la falsificación de ejemplares. Pero, otros argumentan que las consolas y los discos ya son lo suficientemente caros como para exigir gastos extra.  </p><br><p>Esta temporada, las grandes marcas lanzaron nuevas consolas para el público y sorprendieron con precios muy similares, lo que hizo que la competencia en el mercado fuera mínima.   </p>',
      tipo: 'Falsa',
      feedback_correcto: '¡Más allá del titular! Sigue así, entre más profundices en la información que lees, menos propenso serás a consumir y/o compartir información falsa. Evita quedarte únicamente con la información que ves en el titular.',
      feedback_incorrecto: '¡No creas únicamente en el titular! Encontrarás que es una de las prácticas más frecuentes para hacerte caer y compartir información falsa, mediante titulares que exageran y/o presentan la información de manera errónea. Antes de compartir información, lee el artículo completo.'
    },
    52: {
      titulo: 'Librerías se están quebrando y dejarán de producir libros en físico  ',
      subtitulo: '',
      body: '<p>Las grandes librerías a nivel mundial reducirán al m{aximo, según la Asociación Mundial de Librerias (AML) la cantidad de libros que producen de manera física debido a la disminución de ventas en los últimos años.  El nivel de producción y venta no será el mismo, debido al auge de herramientas digitales y la posibilidad de compra de libros en línea .  </p><br><p>Incluso existen algunas aplicaciones que por un pago mensual de 10 dólares permiten el acceso a más de un millón de libros en línea, lo que genera baja o nula rentabilidad a las librerías, teniendo en en cuenta que tan solo un libro cuesta aproximadamente 10 dólares.  </p><br><p>No obstante, el debate de las personas que prefieren leer libros en físico y las que optan por lo virtual sigue siendo amplio, y solo el declive de las grandes librerías dará un veredicto final sobre este tema.  </p>',
      tipo: 'Falsa',
      feedback_correcto: '¡Más allá del titular! Sigue así, entre más profundices en la información que lees, menos propenso serás a consumir y/o compartir información falsa. Evita quedarte únicamente con la información que ves en el titular.',
      feedback_incorrecto: '¡No creas únicamente en el titular! Encontrarás que es una de las prácticas más frecuentes para hacerte caer y compartir información falsa, mediante titulares que exageran y/o presentan la información de manera errónea. Antes de compartir información, lee el artículo completo.'
    },
    53: {
      titulo: ' WhatsApp empezará a cobrar por su servicio ',
      subtitulo: '',
      body: '<p>La red social WhatsApp cobrará una mensualidad por su servicio, sin embargo este cobro estará incluido con el plan de datos o internet móvil que maneje cada usuario. WhatsApp es utilizada por más de dos mil millones de usuarios en todo el mundo y solo es superada por la red social Facebook.   </p><br><p>WhatsApp es una aplicación de mensajería instantánea en que además de textos, se pueden compartir: fotos, videos, archivos, audios e imágenes animadas llamadas gifs. Además, el año pasado con su nueva actualización permitió la llegada de los populares stickers. Además, muchos usuarios la utilizan en el ámbito empresarial. </p><br><p>Desde que Facebook adquirió WhatsApp ha intentado buscar la rentabilidad de esta red social a través de publicidad y anuncios, pero por ahora no lo ha podido llevar a cabo.  </p>',
      tipo: 'Falsa',
      feedback_correcto: '¡Más allá del titular! Sigue así, entre más profundices en la información que lees, menos propenso serás a consumir y/o compartir información falsa. Evita quedarte únicamente con la información que ves en el titular.',
      feedback_incorrecto: '¡No creas únicamente en el titular! Encontrarás que es una de las prácticas más frecuentes para hacerte caer y compartir información falsa, mediante titulares que exageran y/o presentan la información de manera errónea. Antes de compartir información, lee el artículo completo.'
    },
    54: {
      titulo: ' Si usted tiene apellido de origen italiano podría tener la nacionalidad de ese país ',
      subtitulo: '',
      body: '<p>El gobierno de Italia anunció un programa para beneficiar a las millones de personas descendientes de italianos que se encuentran alrededor del mundo. Por esta razón, si usted tiene algún apellido de origen italiano podría optar por la nacionalidad de este país.   </p><br><p>¿Cómo hacerlo? Es muy fácil, deberá comprobar que su apellido es ampliamente utilizado en Italia. Fiorillo, Benedetti, Ricciulli entre otros, son algunos de los apellidos que podrán acceder  al beneficio del gobierno italiano. De acuerdo con los demás requisitos y junto con una carta en la que se compruebe sus raíces italianas puede dar inicio al proceso.  </p><br><p>Un abogado en temas de imigracion lo puede asesorar y ayudar para que usted y su núcleo familiar puedan ser italianos y por ende pertenecer a la Unión Europea, y así contar con beneficios de movilidad, trabajo y de estudio.  </p>',
      tipo: 'Falsa',
      feedback_correcto: '¡Más allá del titular! Sigue así, entre más profundices en la información que lees, menos propenso serás a consumir y/o compartir información falsa. Evita quedarte únicamente con la información que ves en el titular.',
      feedback_incorrecto: '¡No creas únicamente en el titular! Encontrarás que es una de las prácticas más frecuentes para hacerte caer y compartir información falsa, mediante titulares que exageran y/o presentan la información de manera errónea. Antes de compartir información, lee el artículo completo.'
    },
    55: {
      titulo: 'Radamel Falcao García se encuentra en el hospital  ',
      subtitulo: '',
      body: '<p>El jugador colombiano y delantero del Galatasaray ingresó a la Clínica Country, con el fin de visitar a las personas con enfermedades y donar algunos regalos con motivo de la temporada navideña.  </p><br><p>Falcao, quien se encuentra en Colombia para disputar los partidos de las eliminatorias al Mundial frente a Uruguay y Chile, aprovechó su visita para acompañar a las personas que en este momento necesitan un poco de alegría debido a sus condiciones físicas.  </p><br><p>El jugador de 34 años, que participó en el Mundial de Rusia 2018, espera continuar con su buen rendimiento en el equipo turco para poder fortalecer la delantera de Colombia y conseguir la clasificación a la Copa del Mundo Catar 2022.  </p>',
      tipo: 'Falsa',
      feedback_correcto: '¡Más allá del titular! Sigue así, entre más profundices en la información que lees, menos propenso serás a consumir y/o compartir información falsa. Evita quedarte únicamente con la información que ves en el titular.',
      feedback_incorrecto: '¡No creas únicamente en el titular! Encontrarás que es una de las prácticas más frecuentes para hacerte caer y compartir información falsa, mediante titulares que exageran y/o presentan la información de manera errónea. Antes de compartir información, lee el artículo completo.'
    },
    56: {
      titulo: 'Bandera colombiana en la Torre Eiffel ',
      subtitulo: '',
      body: '<p>La bandera colombiana fue vista hoy en la famosa Torre Eiffel en París, Francia. Al parecer, el gobierno francés quiso tener un gesto de admiración con Colombia, a propósito del Día de la Independencia.  </p>',
      tipo: 'Falsa',
      feedback_correcto: '¡Lo has hecho bien! Una de las estrategias más comunes para hacerte caer en información falsa es la edición de imágenes, para hacer que hechos falsos parezcan reales.',
      feedback_incorrecto: '¡No confíes en todas las fotos que veas en internet! Cada vez es más sencillo manipulas las imágenes digitalmente, lo cual podría hacerte creer en información que no es cierta. Si dudas de la veracidad de una imagen, prueba a subirla a Google para identificar si es o no real.'
    },
    57: {
      titulo: 'Osos polares caminando por una calle en Vancouver',
      subtitulo: '',
      body: '<p>¡El cambio climático es una realidad! Un oso polar sorprendió a los habitantes de Vancouver en Canadá, y se paseó por algunas de sus calles. El animal estuvo unas horas en la ciudad hasta que tomó rumbo hacia el bosque.  </p>',
      tipo: 'Falsa',
      feedback_correcto: '¡Lo has hecho bien! Una de las estrategias más comunes para hacerte caer en información falsa es la edición de imágenes, para hacer que hechos falsos parezcan reales.',
      feedback_incorrecto: '¡No confíes en todas las fotos que veas en internet! Cada vez es más sencillo manipulas las imágenes digitalmente, lo cual podría hacerte creer en información que no es cierta. Si dudas de la veracidad de una imagen, prueba a subirla a Google para identificar si es o no real.'
    },
    58: {
      titulo: 'Nueva especialización en odontología para peces  ',
      subtitulo: '',
      body: '<p>De acuerdo al descubrimiento de peces como el pez Pacu , muchas universidades han visto el potencial que existe en las carreras afines con la salud dental de esta clase de animales, por esto es que ha creado una línea de especialización de odontología para peces.  </p>',
      tipo: 'Falsa',
      feedback_correcto: '¡Lo has hecho bien! Una de las estrategias más comunes para hacerte caer en información falsa es la edición de imágenes, para hacer que hechos falsos parezcan reales.',
      feedback_incorrecto: '¡No confíes en todas las fotos que veas en internet! Cada vez es más sencillo manipulas las imágenes digitalmente, lo cual podría hacerte creer en información que no es cierta. Si dudas de la veracidad de una imagen, prueba a subirla a Google para identificar si es o no real.'
    },
    59: {
      titulo: 'Innedito descubrimiento: Tigre de cinco patas ',
      subtitulo: '',
      body: '<p>Una imagen de un tigre de cinco patas ha dado de qué hablar por estos días. El felino fue visto en un Parque Natural de China y los científicos están tratando de explicar si esta característica física se debe a una mutación natural o a alguna intervención del hombre. </p>',
      tipo: 'Falsa',
      feedback_correcto: '¡Lo has hecho bien! Una de las estrategias más comunes para hacerte caer en información falsa es la edición de imágenes, para hacer que hechos falsos parezcan reales.',
      feedback_incorrecto: '¡No confíes en todas las fotos que veas en internet! Cada vez es más sencillo manipulas las imágenes digitalmente, lo cual podría hacerte creer en información que no es cierta. Si dudas de la veracidad de una imagen, prueba a subirla a Google para identificar si es o no real.'
    },
    60: {
      titulo: 'Tiburón es visto en las costas de Cartagena ',
      subtitulo: '',
      body: '<p>Un tiburón blanco apareció en la costa de Cartagena, Colombia y puso en alerta a las autoridades y a los turistas. El animal, de aproximadamente cinco metros, ha estado nadando por el Mar Caribe y se desconoce desde dónde llegó y si se encuentra con más tiburones de su especie. </p>',
      tipo: 'Falsa',
      feedback_correcto: '¡Lo has hecho bien! Una de las estrategias más comunes para hacerte caer en información falsa es la edición de imágenes, para hacer que hechos falsos parezcan reales.',
      feedback_incorrecto: '¡No confíes en todas las fotos que veas en internet! Cada vez es más sencillo manipulas las imágenes digitalmente, lo cual podría hacerte creer en información que no es cierta. Si dudas de la veracidad de una imagen, prueba a subirla a Google para identificar si es o no real.'
    },
    61: {
      titulo: 'Científicos descubren que el Dióxido de cloro puede curar el COVID-19',
      subtitulo: '',
      body: '<p>Acabar con el coronavirus en menos de 48 horas es posible. Isidro Fuentes confirmó que ya es un hecho la capacidad del poder bactericida y viricida del dióxido de cloro, pues elimina todos los patógenos, sin importar si los microbios son virus, bacterias u hongos. </p><br><p>Esta solución mineral milagrosa, también conocida como MMS o clorito de sodio, podría curar también otro tipo de enfermedades que ya aflijan a la persona, desde alergias, sobrepeso, Alzheimer y hasta aflicciones emocionales.</p><br><p>Esta sustancia, que sirve para desinfectar alimentos y oxigenar los bancos de sangre, también es la cura que las grandes empresas no quieren que conozcas para cuidarte de el COVID-19. Di no al monopolio global de la pandemia.</p>',
      tipo: 'Falsa',
      feedback_correcto: '¡Has hecho bien al desconfiar de esta información! El dióxido de cloro y el clorito sódico reaccionan rápidamente en los tejidos humanos y al ingerirse pueden generar desde leves molestias hasta complicaciones serias como un cuadro digestivo irritativo severo, además de graves trastornos hematológicos.',
      feedback_incorrecto: '¡Cuidado, esta información es falsa! El consumo de dióxido de cloro causa "quemaduras en la mucosa oral y gastrointestinal". Además, no está comprobado científicamente que ayude a curar el Coronavirus o cualquier otro tipo de enfermedad.'
    },
    62: {
      titulo: 'El sudor: señal clave para adelgazar',
      subtitulo: '',
      body: '<p>¿Es usted una persona que ha dedicado meses de su vida a realizar actividad física sin ver resultados? ¿nota que cuando hace ejercicio físico no suda? Puede que no esté haciendo suficiente ejercicio para alcanzar sus metas. </p><br><p>El sudor es una muestra de que el cuerpo está quemando grasas y que el esfuerzo de hoy será la satisfacción de mañana. Para aumentar este factor durante sus sesiones de ejercicio, lo invitamos a hacer ejercicio de día, pues de noche es más difícil sudar; adicionalmente, acompañe sus sesiones de ejercicio con música a la moda, que le permita mantener el ritmo y el estilo.</p>',
      tipo: 'Falsa',
      feedback_correcto: '¡Gran trabajo! Actualmente no hay evidencias que permitan concluir que en la segregación de sudor está la clave para adelgazar. Es recomendable hacer ejercicio de manera regular para oxigenar la sangre y mantener el sistema inmunológico fuerte.',
      feedback_incorrecto: '¡Alerta! Han confiado en información falsa. En realidad, no hay ninguna evidencias que permita concluir que en la segregación de sudor está la clave para adelgazar.'
    },
    63: {
      titulo: 'El uso de tapabocas puede aumentar el riesgo de sufrir infartos',
      subtitulo: '',
      body: '<p>La Doctora Isabell Campos Sierra publicó la semana pasada un estudio en donde demuestra el potencial peligro de usar tapabocas por más de media hora, ya que ""respirar una y otra vez el aire que el cuerpo exhala termina por convertirlo en dióxido de carbono"", lo cual puede terminar en un paro cardiaco. </p><br><p>Debido a lo anterior, se debe limitar el uso de tapabocas a no más de 15 minutos; de lo contrario, la persona que lo use podría sentir mareo, aumento del cansancio, perdida de reflejos y del pensamiento consciente.</p>',
      tipo: 'Falsa',
      feedback_correcto: '¡Así es! El tapabocas previene el contagio del COVID por la saliva. Mientras dure la pandemia la indicación es utilizarlo para reducir el riesgo de contagio. Luego nos veremos de nuevo sin ellos. El personal médico en los hospitales lo usa regularmente para proteger su salud.',
      feedback_incorrecto: '¡Cuidado, es falsa! El infarto se produce por una enfermedad diferente (ateroesclerosis). Además, en este momento no hay evidencia científica para comprobar una relación entre el uso de tapabocas y el aumento de infartos.'
    },
    64: {
      titulo: 'Cuanto más se froten los dientes al cepillarse, más limpios quedarán.',
      subtitulo: '',
      body: '<p>El cuidado personal depende de cada uno y está en manos de todos velar por generar hábitos saludables. Por ejemplo, el grupo de investigadores OMSO (Odontólogos del Mundo por la Salud Oral) ha demostrado que los dientes deben cepillarse durante mínimo media hora; de lo contrario el lavado de dientes no surtirá efectos. </p><br><p>El método usado por los científicos es altamente confidencial. Sin embargo, el grupo de embajadores por la salud invita a todas las personas a adoptar esta práctica para ver resultados inmediatos en la higiene oral.</p>',
      tipo: 'Falsa',
      feedback_correcto: '¡Correcto! Esta información es falsa. No existen evidencias para afirmar que entre más se froten los dientes al cepillarse, más limpios quedarán.',
      feedback_incorrecto: '¡Ay, era falsa! Deben ser más cuidadosos al confiar en información sustentada en supuestas organizaciones de carácter mundial. Se recomienda investigar la fuente para confirmar la veracidad de la información.'
    },
    65: {
      titulo: 'WhatsApp cambió sus políticas de privacidad sin previo aviso',
      subtitulo: '',
      body: '<p>Esto es importante: Anoche WhatsApp cambió la configuración de privacidad sin previo aviso. Esto significa que todos los grupos en los que estás están ahora abiertos a todo el mundo por defecto, por lo que personas que no conoces pueden añadirte a un grupo sin que lo sepas. Esto puede incluir estafadores, prestamistas, etc.</p><br><p>La única manera de evitar esto es desinstalado WhatsApp y usando aplicaciones que sí respeten la privacidad de sus usuarios. </p><br><p>Si tienes amigos o familiares que aún usen WhatsApp comparte con ellos esta información para evitar que sigan comprometiendo su información.</p>',
      tipo: 'Falsa',
      feedback_correcto: '¡Muy bien! La información que acabas de leer era una cadena falsa que se difundió por WhatsApp a finales del 2020. Generalmente encontrarás este tipo de anuncios directamente en tu aplicación.',
      feedback_incorrecto: '¡Qué lástima! Acabas de caer en una cadena falsa que se difundió por WhatsApp. La mayoría de las veces estas aplicaciones hacen anuncios formales anunciando estos cambios.'
    },
    66: {
      titulo: 'Descubra los derechos que perdió al ser vacunado contra el Covid-19',
      subtitulo: '',
      body: '<p>Las personas que han recibido vacunación contra el Covid-19 ya no son dueñas de sus propios genes, pues han sido alterados por la tecnología ARNm, por lo cual su información genética ahora pertenece a la empresa que produjo el fármaco.</p><br><p>Esto se da en el marco de una sentencia de la corte de Estados Unidos, en donde se establece que ""no se puede patentar el ADN humano, porque era un producto de la naturaleza. Sin embargo, al final del fallo se aprobó que si se llegara a cambiar el genoma humano como se está haciendo hoy mediante la inoculación de ARN mensajero, entonces ese genoma puede patentarse y se clasifica como propiedad privada”</p><br><p>Esta es una invitación a cuidar su propiedad génica y generar conciencia respecto a las vacunas que pueden generar cambios en sus genes.</p>',
      tipo: 'Falsa',
      feedback_correcto: '¡Lo lograste! La información que acabas de leer era una noticia que sacaba de contexto una sentencia de Estados Unidos. Recuerda siempre buscar la relación que tienen las fuentes citadas y tu contexto. ',
      feedback_incorrecto: '¡Ten cuidado! Caíste en una noticia falsa. No existen pruebas de que las vacunas contra el Covid-19 generen cambios en tus genes. La fuente citada está siendo usada fuera de contexto.'
    },
    67: {
      titulo: 'El gobierno argentino impulsa el racionamiento de carne entre sus ciudadanos',
      subtitulo: '',
      body: '<p>Con el objetivo de promover un estilo de vida libre de carnes, el gobierno argentino ha iniciado un proceso para incentivar que sus ciudadanos reemplacen el consumo de comidas de origen animal, por aquellos productos de origen vegetal. </p><br><p>Todos los ciudadanos que sean capturados consumiendo más de una porción de carne a la semana tendrán que prestar 20 horas de servicio social en huertas instaladas por la alcaldía local en diferentes puntos de la ciudad. </p>',
      tipo: 'Falsa',
      feedback_correcto: '¡Excelente! Evitaste caer en una noticia falsa, la cual no tenía fuentes ni elementos que permitiesen rastrear su veracidad.',
      feedback_incorrecto: '¡Caíste en una noticia falsa! Al encontrar información en internet recuerda buscar las fuentes que cita y contrastarlas con otros medios.'
    },
    68: {
      titulo: 'Equipo de científicos chilenos cerca a desarrollar un método para alertar terremotos',
      subtitulo: '',
      body: '<p>Según el Departamento de Física de la Facultad de Ciencias Físicas y Matemáticas de la Universidad de Chile, hay evidencia suficiente para correlacionar los eventos sísmicos, como terremotos, con los cambios magnéticos que se presentan en la tierra.</p><br><p>Uno de los encargados de la institución, Enrique Cordaro, dijo a los medios que: “Este es un método que está en evolución. Al predecir su ocurrencia con una cierta o alta probabilidad, podríamos alertar sobre los efectos indeseables de terremotos, tsunamis y destrucción de obras civiles en ciudades y puertos, pudiendo incluso salvar vidas humanas”.</p><br><p>Este proyecto, cuyos estudios se están realizando desde el 2017, podría replantear las estrategias de los gobiernos para prevenir y evitar pérdidas humanas en las zonas donde este tipo de fenómenos se presenten.</p>',
      tipo: 'Verdadera',
      feedback_correcto: '¡Es una lástima! Desconfiaste de un artículo verdadero, el cual citaba claramente las fuentes de su información. ',
      feedback_incorrecto: '¡Correcto! Aunque muchas veces encontrarás información científica con datos dudosos, es buena señal aquella que incluye los nombres de los investigadores y sus instituciones. '
    },
    69: {
      titulo: '15 años tiene la primera “Niña del año” en la revista Time',
      subtitulo: '',
      body: '<p>Entre más de 5.000 jóvenes nominados en Estado Unidos fue seleccionada Gitanjali Rao, quien a sus 15 año ya ha impulsado varios proyectos revolucionarios: como una aplicación para detectar ciberacoso mediante inteligencia artificial o un instrumento para detectar contenido de plomo en agua potable.</p><br><p>El mensaje de la joven está dirigido a personas de su edad, a quienes invita a mantener un espíritu innovador para resolver todos los problemas que está enfrentando el mundo, como el cambio climático, el ciberacoso e incluso la pandemia del COVID 19. </p>',
      tipo: 'Verdadera',
      feedback_correcto: '¡Ten cuidado! Acabas de desconfiar de un artículo que tenía características en las cuales podrías confiar. ',
      feedback_incorrecto: '¡Bien hecho! Esta era una noticia verdadera con características que permitían comprobar su veracidad, como el nombre del medio y de los implicados en el suceso.'
    },
    70: {
      titulo: 'El futuro de la aviación: combustible de aire',
      subtitulo: '',
      body: '<p>La empresa holandesa Meijerink planea revolucionar la industria de la aviación mediante un innovador sistema que permite capturar CO2, el gas que contribuye al calentamiento global, directamente desde el aire para convertirlo en combustible para aviones. </p><br><p>Aunque es una solución prometedora, aún se encuentra en un estado muy temprano de su desarrollo. Varios grupos escépticos, como la organización Amigos de la Tierra, representados por Jorien de Lege, hacen una invitación a buscar alternativas inmediatas, como, por ejemplo, viajar menos en avión. </p>',
      tipo: 'Verdadera',
      feedback_correcto: '¡Excelente trabajo! A veces encontrarás noticias que parecen de ficción, sin embargo, aprovecha la información que te brindan para descubrir estos alucinantes avances.',
      feedback_incorrecto: '¡Dejaste ir una noticia verdadera! Es verdad que en este momento la empresa Meijerink está desarrollando cómo extraer combustible a partir del CO2 del aire.'
    },
    71: {
      titulo: '¡Deje las malas películas en el pasado! Una nueva herramienta de inteligencia artificial permite predecir las calificaciones de las películas.',
      subtitulo: '',
      body: '<p>Investigadores de la escuela de Ingeniería de la USC Viterbi han entrenado a un modelo de Inteligencia Artificial con cerca de un millar de guiones cinematográficos, lo cual le permite a este sistema predecir, basado en el guion de la película, si tendrá una buena calificación y recepción por parte del público. </p><br><p>Esta herramienta, que usa redes neuronales para identificar los elementos más importantes del guion, podrá servir como herramienta para que directores y productores de cine ajuste elementos de su guion antes de iniciar la grabación del mismo. </p>',
      tipo: 'Verdadera',
      feedback_correcto: '¡Los usos de la inteligencia artificial son cada vez más comunes! No te extrañes si cada vez encuentras más noticias como estas, pues es verdad que pueden hacer cosas increíbles. ',
      feedback_incorrecto: '¡Gran trabajo! La inteligencia artificial cada vez tiene más usos que permiten automatizar cierto tipo de tareas, como en este caso, generar predicciones de puntajes para películas.'
    },
    72: {
      titulo: 'El nuevo récord de BMW no lo bate un auto sino un traje volador que llega a los 300 hm/h',
      subtitulo: '',
      body: '<p>Durante la celebración del evento NEXTGen2020 la empresa BMW, en colaboración con Designworks, han presentado un motor de propulsión eléctrica para trajes tipo Wingsuit, capaz de alcanzar hasta 300 kilómetros por hora en el aire.</p><br><p>El motor, que en este caso se acopló al cuerpo del piloto Peter Salzmann, consta de dos hélices de carbono revestidas y cada una puede alcanzar hasta 25.000 rpm durante un tiempo de aproximadamente 5 minutos.</p><br><p>Esta es una hazaña memorable, teniendo en cuenta que el piloto en caída libre alcanzó los 300 kilómetros por hora, mientras que otros pilotos, sin contar con este motor, a duras penas alcanzan los 100 kilómetros. </p>',
      tipo: 'Verdadera',
      feedback_correcto: 'Hombres volando a altas velocidades, parece mentira pero no lo es. La empresa BMW logró esta increíble hazaña.',
      feedback_incorrecto: 'Increíble ¿verdad?    Esta colaboración permitió generar un motor con energía eléctrica para impulsar al piloto Salzmann a más de 300 km/h por los aires.'
    },
    73: {
      titulo: '30 minutos de ejercicio para una vida saludable',
      subtitulo: '',
      body: '<p>Mantener el corazón y los pulmones estimulados es una tarea que no requiere mucho tiempo. Según la organización Sanitas, con un mínimo de media hora de ejercicio moderado, durante al menos 5 días a la semana, se logará generar un impacto positivo en el organismo de jóvenes y adultos. </p><br><p>Al realizar ejercicio aumenta el ritmo cardíaco y de la respiración, lo cual mantiene fuertes a estos órganos. Adicionalmente, la actividad física ayuda a regular el buen humor, disminuir los niveles de estrés y mejorar la calidad del sueño.</p>',
      tipo: 'Verdadera',
      feedback_correcto: '¡Desconfiaste de una información que era cierta! Esta información es verdadera. El artículo cita a fuentes oficiales y añaden datos que permiten entender el contexto de la información que allí se presenta.',
      feedback_incorrecto: '¡Correcto! Esta información es verdadera. El artículo cita a fuentes oficiales y añaden datos que permiten entender el contexto de la información que allí se presenta.'
    }
  },
  'Feedback por puntaje': [
    {
      Puntaje: 'Puntajes más bajos',
      Mensaje: '¡Caiste en el cuento!\r\nDebes ser más cuidado, estas confiando demasiado en información que no deberías. Puedes seguir practicando y mejorando tus habilidades para diferenciar la información falsa de la real.'
    },
    {
      Puntaje: 'Puntaje medio',
      Mensaje: '¡Detector de cuentos!\r\nVas por buen camino ¡pero puede ser mejor! Recuerda  analizar siempre la información que consumes antes de confiar en ella o compartirla en tus redes sociales. '
    },
    {
      Puntaje: 'Puntaje superior al medio',
      Mensaje: '¡Cazador de noticias falsas!\r\nHas alcanzado un excelente puntaje, demostraste que identificas la información real de la falsa y solo fallas ocasionalmente.'
    },
    {
      Puntaje: 'Puntaje excelente',
      Mensaje: '¡Experto de la información!\r\nAcabas de demostrar que eres responsable al momento de consumir y compartir información, siendo capaz de identificar casi cualquier inidicio de información falsa.'
    }
  ]
}

const state = reactive({
  vidas: null,
  puntos: null,
  noticias: datosjuego.noticias,
  noticias_disponibles: [],
  multiplicador: null,
  racha: null,
  current_new: null,
  current_new_id: null,
  current_guess: null
})

export default function useJuego () {
  const shuffleNews = () => {
    const noticiasDisponibles = [...Array(73).keys()]
    for (let i = noticiasDisponibles.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      const temp = noticiasDisponibles[i]
      noticiasDisponibles[i] = noticiasDisponibles[j]
      noticiasDisponibles[j] = temp
    }
    state.vidas = 3
    state.puntos = 0
    state.multiplicador = 1
    state.racha = 0
    state.noticias_disponibles = noticiasDisponibles
  }

  const popNew = () => {
    const newId = state.noticias_disponibles.pop()
    state.current_new_id = newId + 1
    state.current_new = state.noticias[newId + 1]
  }

  return {
    ...toRefs(state),
    shuffleNews,
    popNew
  }
}
